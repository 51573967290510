import React from "react";

const Whychoose = () => {
  return (
<div className="relative py-20 px-4 sm:px-10 md:px-20 lg:px-64">
  <div className="flex flex-col lg:flex-row items-center lg:items-start">
    <div className="lg:order-2 lg:ml-auto lg:mr-16">
      <img
        src="/imgs/Why.jpg"
        className="h-40 w-40 sm:h-48 sm:w-48 md:h-64 md:w-64 lg:h-72 lg:w-96 object-cover rounded-xl mb-6 lg:mb-0 mt-20 lg:mt-0"
        alt="Why choose us"
      />
    </div>
    <div className="lg:max-w-[60%]">
    <h1 className="text-center lg:text-left text-3xl sm:text-4xl lg:text-5xl py-3 lg:mr-8 mt-0">
  Why Choose Maekra?
</h1>

      <p className="text-center lg:text-left text-lg sm:text-xl lg:text-2xl pt-4 lg:mr-12">
        At Maekra, we blend creativity with precision to deliver visual
        merchandising that not only meets but exceeds your expectations. Our
        team of experts is dedicated to bringing your ideas to life with
        innovative solutions that resonate with your audience. Partner with us
        to create visual stories that captivate and convert.
      </p>
    </div>
  </div>
</div>


  );
};

{
  /* <div className="py-20 bg-blue-100">
<h1 className="text-center text-3xl md:text-4xl lg:text-5xl pt-5 pb-16">
  About Us
</h1>
<div className="flex justify-center items-center pb-10">
  <img
    src="/imgs/tagline-removebg.png"
    className="h-40 md:h-52"
    alt="Flowbite Logo"
  />
</div>
<h1 className="text-center text-2xl md:text-3xl lg:text-4xl py-5">
  A Legacy of Excellence
</h1>
<p className="text-center pt-4 text-base md:text-lg lg:text-xl pb-10 px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40">
  Maekra is more than just a visual merchandising and branding company; it
  is the continuation of a proud family legacy. Since the 1960s, our
  family has been at the forefront of the industry, crafting innovative
  and impactful branding solutions that have stood the test of time.
  Founded by my grandfather and continued by my father, our journey is a
  testament to dedication, craftsmanship, and a relentless pursuit of
  excellence.
</p>
<h1 className="text-center text-xl md:text-2xl lg:text-3xl pt-5">
  Our Story
</h1>
<div className="flex flex-col lg:flex-row items-center lg:justify-between px-4 pt-5 lg:pt-0 sm:px-8 md:px-12 lg:px-20 xl:px-40">
  <img
    src="/imgs/eknoor.jpeg"
    className="h-40 w-40 md:h-48 md:w-48 lg:h-52 lg:w-52 object-cover rounded-full"
    alt="Eknoor Kaur"
  />
  <p className="text-center md:text-left pt-6 text-base md:text-lg lg:text-xl lg:ml-8">
    I am Eknoor Kaur, the third generation to step into this creative and
    dynamic field. With a degree from Symbiosis University, Pune, and a
    passion for art, design, and creation, I have embarked on a mission to
    take our family legacy to new heights with Maekra. Combining
    traditional craftsmanship with modern innovation, we aim to provide
    exceptional visual merchandising and branding solutions that captivate
    and inspire.
  </p>
</div>
</div> */
}
export default Whychoose;
