import React from "react";
import Herosection from "../components/homepg/Herosection";
import Whatwedo from "../components/homepg/Whatwedo";
import Whychoose from "../components/homepg/WhyChoose";
import Logos from "../components/homepg/Logos";
import Aboutus from "../components/homepg/Aboutus";
import VCV from "../components/homepg/VCV";
// import { MagicUI } from "../components/homepg/MagicUI";
const Home = () => {
  return (
    <>
      {/* <MagicUI /> */}
      <Herosection />
      <Whatwedo />
      <Whychoose />
      <Logos />
      <Aboutus />
      <VCV />
    </>
  );
};

export default Home;
