import React from 'react';
const logoss = [
  'logo1.png',
  'logo2.png',
  'logo3.png',
  'logo4.png',
  'logo5.png',
  'logo6.png',
  'logo7.png',
  'logo8.png',
  'logo9.png',
  'logo10.png',
  'logo11.png',
  'logo12.png',
  'logo13.png',
  'logo14.png',
  'logo15.png',
  'logo16.png',
  'logo17.png',
  'logo18.png',
  'logo19.png',
  'logo20.png',
];
const Logos = () => {
    return (
      <section className="py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-center text-3xl lg:text-5xl pb-8 lg:pb-24">
          Our Partners
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
          {logoss.map((logo, index) => (
            <div key={index} className="p-4 flex justify-center items-center">
              <img
                src={`/imgs/clientlogos/${logo}`}
                alt={`Customer ${index + 1}`}
                className=" h-10 lg:h-16 object-contain hover:opacity-80"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
   
);
}
export default Logos;


// import React from 'react';

// const HappyCustomers = () => {
//   const logos = [
//     'logo1.png',
//     'logo2.png',
//     'logo3.png',
//     'logo4.png',
//     'logo5.png',
//     'logo6.png',
//     'logo7.png',
//     'logo8.png',
//     'logo9.png',
//     'logo10.png',
//     'logo11.png',
//     'logo12.png',
//     'logo13.png',
//     'logo14.png',
//     'logo15.png',
//     'logo16.png',
//   ];

//   return (
//     <section className="py-12 bg-gray-50">
//       <div className="container mx-auto text-center">
//         <h2 className="text-2xl font-bold text-gray-800 mb-8">
//           Our Happy Customers
//         </h2>
//         <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
//           {logos.map((logo, index) => (
//             <div key={index} className="p-4 flex justify-center items-center">
//               <img
//                 src={`path_to_your_logos_folder/${logo}`}
//                 alt={`Customer ${index + 1}`}
//                 className="h-20 object-contain hover:opacity-80"
//               />
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default HappyCustomers;
