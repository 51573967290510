import React from "react";

const productHeadlines = [
  "Inshop branding",
  "Backlit signages and boards",
  "Non-lit signages and boards",
  "Fabric signages (eco-friendly option)",
  "Ambient advertising",
  "Window setup",
  "Custom neon signs",
  "Safety and Directional signs",
  "Custom LED signs",
  "Acrylic and Metal signages",
  "One-way vision",
  "Frost vinyls",
  "Promotional point of purchase advertising",
  "Standees and danglers",
  "Wall and window graffiti prints",
  "Wallpapers",
];

const Products = () => {
  return (
    <section className="py-12">
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
        {productHeadlines.map((headline, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center p-6"
          >
            <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center">
              {headline}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
              <img
                src={`/imgs/Products/image${index * 2 + 1}.jpg`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `/imgs/Products/image${index * 2 + 1}.jpeg`;
                }}
                alt={`Product ${index * 2 + 1}`}
                className="object-cover w-full h-48 rounded-lg"
              />
              <img
                src={`/imgs/Products/image${index * 2 + 2}.jpg`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `/imgs/Products/image${index * 2 + 2}.jpeg`;
                }}
                alt={`Product ${index * 2 + 2}`}
                className="object-cover w-full h-48 rounded-lg"
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Products;
