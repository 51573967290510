import React from 'react';

const VCV = () => {
    return (
<div className='grid grid-cols-1 gap-6 px-4 pb-20 md:grid-cols-2 lg:grid-cols-3 lg:gap-8'>
  <a href="#" className=" lg:mx-12 block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center">Our Vision</h5>
    <p className="font-normal text-xl text-gray-700 dark:text-gray-400">
      At Maekra, we believe in the power of visual storytelling. Our vision is to transform brands into immersive experiences that resonate with audiences and leave lasting impressions. We strive to blend creativity with precision, delivering solutions that not only meet but exceed our clients’ expectations.
    </p>
  </a>

  <a href="#" className="lg:mx-12 block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center">Our Values</h5>
    <p className="font-normal text-xl text-gray-700 dark:text-gray-400">
      • Innovation: Continuously pushing the boundaries of design and technology to create cutting-edge solutions.<br />
      • Quality: Upholding the highest standards of craftsmanship in every project we undertake.<br />
      • Integrity: Building lasting relationships with our clients based on trust and transparency.<br />
      • Sustainability: Committing to eco-friendly practices and materials that benefit our planet.
    </p>
  </a>

  <a href="#" className="lg:mx-12 block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center">Our Commitment</h5>
    <p className="font-normal text-xl text-gray-700 dark:text-gray-400">
      Maekra is dedicated to bringing your brand vision to life with creativity, precision, and passion. We work closely with our clients to understand their unique needs and provide tailored solutions that elevate their brand presence. From in-shop displays to custom LED signages, our team is equipped to handle projects of any scale with the utmost professionalism and attention to detail.
    </p>
  </a>
</div>
);
}
export default VCV;